import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useSound from 'use-sound';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { selectSoundtrack, updatePlayStatus } from '../store/reducers/soundtrackSlice';

import { BreakPoint } from "../variables"

import AudioIcon from "../../images/icons/audio-01.png"
import AudioPlayingIcon from "../../images/icons/audio-playing-01.png"

const wobble = keyframes`
	0%   { transform: translateY(0); }
	30%  { transform: translateY(-10px); }
	50%  { transform: translateY(0); }
	100% { transform: translateY(0); }
`;
const Wrapper = styled.div`
	position: fixed;
	z-index: 1;
	bottom: 18px;
	left: 20px;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center; 
	cursor: pointer;
	${props => props.interaction == false && css`
		animation: ${wobble} 1s 5;
	`}
	&:hover {
		transform: scale(1.1);
	}
	img {
		width: 30px;
	}
	@media ${BreakPoint.sm} {
		bottom: 45px;
		left: 50px;
		img {
			width: 35px;
		}
	}
	@media (min-width: 1270px) {
		left: calc(50% - 580px);
	}
`

export const ArcadeSoundTrack = () => {

	const [interaction, setInteraction] = useState(false);

	const soundtrack = useSelector(selectSoundtrack);
	const dispatch = useDispatch();

	const [play, { stop }] = useSound(soundtrack.file);

	const handleClick =  () => {
		setInteraction(true)
        if(soundtrack.isPlaying) {
			stop()
			dispatch(updatePlayStatus(false))
		} else {
			play()
			dispatch(updatePlayStatus(true))
		}
	}

	useEffect(() => {
		
	},[]);

	return (
		<Wrapper interaction={interaction} onClick={() => handleClick()}>
			<img src={soundtrack.isPlaying ? AudioPlayingIcon : AudioIcon}/>
		</Wrapper>
	)
}
