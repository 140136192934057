import React, { useRef } from "react"

import { Field } from "../../styles/"

export const InputField = ({ autocomplete, align, disabled, label, maxLength, name, onChange, placeholder, required, type, value, setValue }) => {

	const inputRef = useRef();

	function handleChange(e) {
		e.persist();
		setValue(currentValues => ({
			...currentValues,
			[name]: inputRef.current.value
		}))
	}

	return (
		<Field align={align}>
			<label>
				{label &&
					<p>{label}{required && '*'}</p>
				}
				<input
					type={ type }
					name={ name }
					placeholder={ placeholder }
					defaultValue={ value }
					ref={inputRef}
					onChange={ onChange ?? handleChange }
					required={ required ? true : false }
					disabled={ disabled }
					maxLength={ maxLength }
					autoComplete={ autocomplete ?? 'off' }/>
			</label>
		</Field>
	)
}
