import React from "react"

const formatTime = time => {
    return `${(parseInt(time / 60, 10) + "").padStart(2, "0")}:${(
      "" +
      (time % 60)
    ).padStart(2, "0")}`;
};

export const Time = ({ time }) => {

	return (
		<> {formatTime(time)}</>
	)
}