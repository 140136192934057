import React from "react"
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import LogoImage from "../../images/logos/pixelated-logo-01.png"

const Wrapper = styled.a`
	img {
		160px;
	}
`

export const Logo = ({theme}) => {

	return (
		<Wrapper href="https://satellitecreative.com/" target="_blank">
			<img src={LogoImage}/>
		</Wrapper>
	)
}
