import { createSlice } from '@reduxjs/toolkit'

export const puzzleBoardSlice = createSlice({
  name: 'puzzleBoard',
  initialState: {
    rows: 4,
    columns: 4
  },
  reducers: {
    updateBoardSize: (state, action) => {
      const { rows, columns } = action.payload
      state.rows = rows
      state.columns = columns
    }
  }
})

export const { updateBoardSize } = puzzleBoardSlice.actions

export const selectPuzzleBoard = state => state.puzzleBoard

export default puzzleBoardSlice.reducer