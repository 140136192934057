import styled from 'styled-components'
import { BreakPoint } from '../../variables/'

export const GameHelp = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	@media ${BreakPoint.sm} {
		padding: 40px;
	}
	> div {
		background: #685bc7;
		width: 100%;
		max-width: 700px;
		max-height: 700px;
		padding: 40px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
	}
`