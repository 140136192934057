import { darken, lighten } from 'polished'

//Black
export const Black = {
	primary: "#1d1d1d",
	secondary: "#fff" 
}
//White
export const White = {
	primary: "#fff",
	secondary: "#1d1d1d" 
}
//Grey
export const Grey = {
	primary: "#d3d9df",
	secondary: "#1d1d1d"
}
export const GreyLight = {
	primary: "#F6F7F8",
	secondary: "#1d1d1d"
}
export const GreyDark = {
	primary: "#596673",
	secondary: "#ffffff",
	darken: darken(0.05, "#ddd")
}
//Green
export const Green = {
	primary: "#53AF32",
	secondary: "#fff"
}
export const GreenDark = {
	primary: "#044D52",
	secondary: "#fff"
}
//Blue
export const Blue = {
	primary: "#174e80",
	secondary: "#fff"
}
//Yellow
export const Yellow = {
	primary: "#FEDD00",
	secondary: "#2D2D2B"
}
//Purple
export const Purple = { 
	primary: "#685bc7", 
	secondary: "#fff"
}