import { createSlice } from '@reduxjs/toolkit'

const dimension = { width: 400, height: 400 };

export const puzzleGameSlice = createSlice({
  name: 'puzzleGame',
  initialState: {
    swap: false,
    tiles: [],
    tilesStatus: {},
    minSwapsMode: {
      threshold: 10
    },
    isPlaying: true
  },
  reducers: {
    swapTiles: (state, action) => {
      state.swap = true
    },
    resetSwap: (state, action) => {
      state.swap = false
      state.tiles = []
    },
    selectTile: (state, action) => {
      state.tiles = [...state.tiles, action.payload]
    },
    unselectTile: (state, action) => {
      const coords = action.payload
      state.tiles = state.tiles.filter(
        tile => !tile.every((coord, index) => coord === coords[index])
      )
    },
    resetTileStatus: (state, action) => {
      state.tilesStatus = {}
    },
    updateTileStatus: (state, action) => {
      state.tilesStatus = {
        ...state.tilesStatus,
        [action.payload.id]: action.payload.status
      }
    },
    updateGameStatus: (state, action) => {
      state.isPlaying = action.payload
    },
  }
})

export const { swapTiles, resetSwap, selectTile, unselectTile, updateTileStatus, resetTileStatus, updateGameStatus } = puzzleGameSlice.actions

export const selectPuzzleGame = state => state.puzzleGame

export default puzzleGameSlice.reducer