import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

import Favicon from "../../images/logos/favicon.png"

export const Meta = ({ title, description, image, pathname, robots }) => {

	const {
		title: defaultTitle,
    	description: defaultDescription,
		siteUrl,
		defaultImage
	} = useSiteMetadata()

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: image || defaultImage,
		colour: `#685bc7`,
		url: `${siteUrl}${pathname || ``}`,
		robots: robots || 'all',
		lang: `en-gb`
	}

	return (
		<>	
			<html lang={seo.lang}/>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description}></meta>
			<meta name="image" content={seo.image} />

			<meta name="og:locale" content={seo.lang} />
			<meta name="og:site_name" content={defaultTitle} />
			<meta name="og:type" content="website" />
			<meta name="og:title" content={seo.title} />
			<meta name="og:description" content={seo.description} />
			<meta name="og:image" content={seo.image} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:url" content={seo.url} />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:image" content={seo.image} />

			<meta name="theme-color" content={seo.colour} />
			<meta name="robots" content={seo.robots}/>
			<link rel="icon" href={Favicon} />
		</>
	)
}
