import styled from 'styled-components'
import { FontSize } from '../../variables/'

export const GameNav = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	width: 100%;
	padding: 20px 40px 20px 100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background: #685bc7;
	color: #fff;
	${FontSize.md}
	a, p {
		text-decoration: none;
		color: inherit;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
`