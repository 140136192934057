import styled from 'styled-components'
import { FontSize } from '../variables/'

export const ErrorText = styled.div`
	color: red;
	margin: 20px 0;
	&:last-child {
		margin-bottom: 0;
	}
`
