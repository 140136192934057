import React from "react"
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import HelpIcon from "../../images/icons/help-01.png"
import HomeIcon from "../../images/icons/home-01.png"
import LeaderboardIcon from "../../images/icons/leaderboard-01.png"

//components
import { Logo } from "../partials/"
import { Black, Purple, FontSize, BreakPoint } from "../variables"
import { Restrict } from "../styles/"

const Wrapper = styled.div`
	padding: 15px;
	background: ${Purple.primary};
	color: #fff;
	a {
		color: inherit;
	}
	@media ${BreakPoint.sm} {
		padding: 20px;
	}
`
const Content = styled.div`
	display: flex;
	align-items: center;
	${FontSize.xsm}
	> * {
		flex: 1;
	}
`
const Center = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const IconWrapper = styled.div`
	display: flex;
	margin: -3px;
	&:first-child {
		> a:first-child {
			margin-left: 40px;
		}
	}
	&:last-child {
		justify-content: end;
	}
	img {
		width: 30px;
		margin: 3px;
	}
	@media ${BreakPoint.sm} {
		margin: -5px;
		&:first-child {
			> a:first-child {
				margin-left: 53px;
			}
		}
		img {
			width: 35px;
			margin: 5px;
		}
	}
`

export const NavBar = ({homeLink, rightIcons}) => {

	return (
		<Wrapper>
			<Restrict>
				<Content>
					<IconWrapper>
						{homeLink &&
							<Link to="/"><img src={HomeIcon}/></Link>
						}
					</IconWrapper>
					<Center>
						<Logo/>
					</Center>
					<IconWrapper>
						{rightIcons}
					</IconWrapper>
				</Content>
			</Restrict>
		</Wrapper>
	)
}
