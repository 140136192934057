import styled from 'styled-components'
import { BreakPoint } from '../../variables/'

export const GameTitle = styled.h1`
	text-shadow: -2px 0 0 #fdff2a,
	-4px 0 0 #df4a42,
	2px 0 0 #91fcfe,
	4px 0 0 #4405fc;
	text-align: text;
	div {
		position: relative;
		&:last-child {
		margin-bottom: 0;
		}
		span {
		line-height: 100%;
		&:first-child {
			position: relative;
			z-index: 1;
			font-size: ${props => props.small ? '2.4rem' : '2.5rem'};
			color: #FEDD00;
		}
		&:last-child {
			width: 100%;
			position: absolute;
			z-index: 0;
			bottom: 5%;
			left: 0;
			color: black;
			font-size: ${props => props.small ? '2.5rem' : '2.7rem'};
		}
		}
		@media ${BreakPoint.sm} {
		margin-bottom: 10px;
		span {
			&:first-child {
			font-size: ${props => props.small ? '3.2rem' : '4rem'};
			}
			&:last-child {
			font-size: ${props => props.small ? '3.2rem' : '4.3rem'};
			bottom: 0%;
			}
		}
		}
		@media ${BreakPoint.md} {
		margin-bottom: 20px;
		span {
			&:first-child {
			font-size: ${props => props.small ? '5rem' : '6rem'};
			padding: 0 1.4rem;
			}
			&:last-child {
			font-size: ${props => props.small ? '5.2rem' : '6.3rem'};
			bottom: 0%;
			}
		}
		}
		@media ${BreakPoint.lg} {
		margin-bottom: 25px;
		span {
			&:first-child {
			font-size: ${props => props.small ? '5rem' : '7rem'};
			padding: 0 1.4rem;
			}
			&:last-child {
			font-size: ${props => props.small ? '5.2rem' : '7.3rem'};
			bottom: 0%;
			}
		}
		}
		@media ${BreakPoint.xl} {
		margin-bottom: 25px;
		span {
			&:first-child {
			font-size: ${props => props.small ? '5rem' : '8rem'};
			}
			&:last-child {
			font-size: ${props => props.small ? '5.2rem' : '8.3rem'};
			bottom: 0%;
			}
		}
		}
	}
`