import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null
  },
  reducers: {
    updateUser: (state, action) => {
      state.id = action.payload
    }
  }
})

export const { updateUser } = userSlice.actions

export const selectUser = state => state.user.id

export default userSlice.reducer