import React from "react"
import styled, { css } from 'styled-components'
import { lighten, transparentize } from 'polished'
import { FontSize, Yellow, GreyDark } from "../../variables"
import { Field } from "../../styles/"

const sliderStyles = {
	thumbColour: Yellow.primary,
	thumbRadius: "50%",
	thumbHeight: "30px",
	thumbWidth: "30px",
	thumbShadowSize: "0px",
	thumbShadowBlur: "10px",
	thumbShadowSpread: "2px",
	thumbShadowColour: transparentize(0.8, "#111"),
	thumbShadowColourLighten: lighten(0.05, "#111"),
	thumbBorderWidth: "0px",
	thumbBorderColour: "white",

	trackColour: GreyDark.primary,
	trackColourDarken: GreyDark.darken,
	trackWidth: "100%",
	trackHeight: "17px",
	trackShadowSize: "0px",
	trackShadowBlur: "0px",
	trackShadowColour: "#222",
	trackShadowColourLighten: lighten(0.05, "#222"),
	trackBorderWidth: "0px",
	trackBorderColour: "transparent",
	trackRadius: "10px",

	contrast: "5%"
};
const RangeWrapper = styled.div`
	display: flex;
	align-items: center;
	${FontSize.xsm}
	${props => props.displayMinMax && css`
		&:before {
			content: "${props => props.min}${props => props.suffix}";
			margin-right: 10px;
		}
		&:after {
			content: "${props => props.max}${props => props.suffix}";
			margin-left: 10px;
		}
	`}
`
const RangeSlider = styled.input`
	-webkit-appearance: none;
	width: ${props => props.styles.trackWidth};
	background: transparent;
	padding: 0!important;
	border: none!important;
	&::-webkit-slider-runnable-track {
		width: ${props => props.styles.trackWidth};
		height: ${props => props.styles.trackHeight};
		cursor: pointer;
		box-shadow: ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowBlur}
					${props => props.styles.trackShadowColour}, 
					0px 0px ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowColourLighten};
		background: ${props => props.styles.trackColour};
		border-radius: ${props => props.styles.trackRadius};
		border: ${props => props.styles.trackBorderWidth} solid ${props => props.styles.trackBorderColour};
	}
	&::-webkit-slider-thumb {
		box-shadow: ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowBlur} 
					${props => props.styles.thumbShadowSpread}  
					${props => props.styles.thumbShadowColour}, 
					0px 0px ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowColourLighten};
		border: ${props => props.styles.thumbBorderWidth} solid ${props => props.styles.thumbBorderColour};
		width: ${props => props.styles.thumbWidth};
		height: ${props => props.styles.thumbHeight};
		border-radius: ${props => props.styles.thumbRadius};
		background: ${props => props.styles.thumbColour};
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: calc((((${props => props.styles.trackBorderWidth} * 2) + ${props => props.styles.trackHeight}) / 2) - (${props => props.styles.thumbHeight} / 2));
	}
	&::-moz-range-track {
		width: ${props => props.styles.trackWidth};
		height: ${props => props.styles.trackHeight};
		cursor: pointer;
		box-shadow: ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowBlur} 
					${props => props.styles.trackShadowColour}, 
					0px 0px ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowColourLighten};
		background: ${props => props.styles.trackColour};
		border-radius: ${props => props.styles.trackRadius};
		border: ${props => props.styles.trackBorderWidth} solid ${props => props.styles.trackBorderColour};
	}
	&::-moz-range-thumb {
		box-shadow: ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowBlur} 
					${props => props.styles.thumbShadowSpread}  
					${props => props.styles.thumbShadowColour}, 
					0px 0px ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowColourLighten};
		border: ${props => props.styles.thumbBorderWidth} solid ${props => props.styles.thumbBorderColour};
		width: ${props => props.styles.thumbWidth};
		height: ${props => props.styles.thumbHeight};
		border-radius: ${props => props.styles.thumbRadius};
		background: ${props => props.styles.thumbColour};
		cursor: pointer;
	}
	&::-ms-track {
		width: ${props => props.styles.trackWidth};
		height: ${props => props.styles.trackHeight};
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		border-width: ${props => props.styles.thumbWidth} 0;
		color: transparent;
	}
	&::-ms-fill-lower {
		background: ${props => props.styles.trackColourDarken};
		border: ${props => props.styles.trackBorderWidth} solid ${props => props.styles.trackBorderColour};
		border-radius: calc(${props => props.styles.trackRadius}*2);
		box-shadow: ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowBlur} 
					${props => props.styles.trackShadowColour}, 
					0px 0px ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowColourLighten};
	}
	&::-ms-fill-upper {
		background: ${props => props.styles.trackColour};
		border: ${props => props.styles.trackBorderWidth} solid ${props => props.styles.trackBorderColour};
		border-radius: calc(${props => props.styles.trackRadius}*2);
		box-shadow: ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowBlur} 
					${props => props.styles.trackShadowColour}, 
					0px 0px ${props => props.styles.trackShadowSize} 
					${props => props.styles.trackShadowColourLighten};
	}
	&::-ms-thumb {
		box-shadow: ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowBlur} 
					${props => props.styles.thumbShadowSpread}  
					${props => props.styles.thumbShadowColour}, 
					0px 0px ${props => props.styles.thumbShadowSize} 
					${props => props.styles.thumbShadowColourLighten};
		border: ${props => props.styles.thumbBorderWidth} solid ${props => props.styles.thumbBorderColour};
		width: ${props => props.styles.thumbWidth};
		height: ${props => props.styles.thumbHeight};
		border-radius: ${props => props.styles.thumbRadius};
		background: ${props => props.styles.thumbColour};
		cursor: pointer;
	}
`

export const RangeField = ({ onChange, defaultValue, displayMinMax, id, label, max, min, step, suffix, value }) => {

	return (
		<Field>
			<label>
				{label &&
					<p>{label}: {value}{suffix}</p>
				}
				<RangeWrapper
					min={min}
					max={max}
					displayMinMax={displayMinMax ?? true}
					suffix={suffix}>
					<RangeSlider
						styles={sliderStyles}
						type="range"
						min={min}
						max={max}
						defaultValue={defaultValue}
						step={step}
						id={id}
						onChange={onChange}/>
				</RangeWrapper>
			</label>
		</Field>
	)
}
