import styled from 'styled-components'
import { Link } from "gatsby"
import { FontSize } from '../../variables/'

export const GameCard = styled(Link)`
	border: 5px solid black;
	border-radius: 20px;
	padding: 20px;
	background: white;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	max-width: 300px;
	img {
	margin-right: 10px;
	}
	p {
	${FontSize.sm}
	text-transform: uppercase;
	}
	&:hover {
	transform: scale(0.9);
	}
`