import { createSlice } from '@reduxjs/toolkit'

import SoundTrackFile from "../../../audio/arcade-soundtrack-01.mp3"

export const soundtrackSlice = createSlice({
  name: 'soundtrack',
  initialState: {
    file: SoundTrackFile,
    isPlaying: false
  },
  reducers: {
    updatePlayStatus: (state, action) => {
      state.isPlaying = action.payload
    }
  }
})

export const { updatePlayStatus } = soundtrackSlice.actions

export const selectSoundtrack = state => state.soundtrack

export default soundtrackSlice.reducer