import styled from 'styled-components'

import { FontSize } from "../variables"

export const Table = styled.table`
	width: 100%;
	th {
		${FontSize.xxsm}
		font-weight: bold;
		text-align: left;
	}
	td {
		border: 5px solid transparent;
	}
`
