import styled from 'styled-components'
import { FontSize, Blue } from '../variables/'

export const TextContent = styled.div`
	color: ${props => props.colour ? props.colour : "inherit"};
	text-align: ${props => props.center ? "center" : "inherit"};
	width: 100%;
	> * {
		margin-bottom: 0;
		&:last-child {
			margin-bottom: 0!important;
		}
	}
	h1, h2, h3, h4, h5, h6, p {
		margin-bottom: 20px;
	}
	h1 {
		${FontSize.xl}
	}
	h2 {
		${FontSize.lg}
	}
	h3 {
		${FontSize.md}
	}
	h4, h5, h6, p, ul {
		${FontSize.sm}
	}
	ul {
		list-style-type: disc;
		margin-left: 20px;
	}
`
