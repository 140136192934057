import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userSlice'
import themeReducer from './reducers/themeSlice'
import soundtrackReducer from './reducers/soundtrackSlice'
import puzzleBoardReducer from './reducers/puzzleBoardSlice'
import puzzleGameReducer from './reducers/puzzleGameSlice'
import puzzleImageReducer from './reducers/puzzleImageSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    soundtrack: soundtrackReducer,
    puzzleBoard: puzzleBoardReducer,
    puzzleGame: puzzleGameReducer,
    puzzleImage: puzzleImageReducer
  }
})