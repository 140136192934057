import React from "react"
import PropTypes from "prop-types"
import { CookiesProvider } from 'react-cookie';

//redux
import { Provider } from 'react-redux'
import store from '../store/configureStore'
import { ArcadeSoundTrack } from "../special"

export const  RootLayout = ({ children }) => {

  return (
      <Provider store={store}>
        <CookiesProvider>
          { children }
          <ArcadeSoundTrack/>
        </CookiesProvider>
      </Provider>
	)
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
