import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  to {visibility: hidden;}
`;
export const FlashingText = styled.h2`
	text-shadow: -2px 0 0 #fdff2a,
	-4px 0 0 #df4a42,
	2px 0 0 #91fcfe,
	4px 0 0 #4405fc;
	animation: ${blink} 1s steps(4, start) infinite;
	text-align: ${props => props.center ? 'center' : 'left'};
	font-size: ${props => props.large ? '5rem' : '2.5rem'};
	line-height: 140%;
`